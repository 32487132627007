
import { Component, Vue, Prop } from "vue-property-decorator";
import SendPhoneAccessCodeMutation from "@/apollo/SendPhoneAccessCodeMutation.graphql";
import PhoneNumber from "@/components/phonenumber.vue";
import RelButton from "@/components/button.vue";
import { E164Number, parsePhoneNumberFromString } from "libphonenumber-js";
import { ApolloError } from "apollo-client";

@Component<LoginInputPage>({
	components: {
		PhoneNumber,
		RelButton,
	},

	scrollToTop: true,

	head() {
		const ogImageUrl = new URL(require("~/assets/share_get-app_default_black.png"), this.$config.HOST_WEB);

		return {
			title: "Sign up / Sign in",
			meta: [
				{
					hid: "description",
					name: "description",
					content: "Sign up with your mobile phone number to start searching for listings with your agent.",
				},
				{
					hid: "og:image",
					property: "og:image",
					content: ogImageUrl.toString(),
				},
				{
					hid: `og:image:width`,
					property: "og:image:width",
					content: "1280",
				},
				{
					hid: `og:image:height`,
					property: "og:image:height",
					content: "720",
				},
			]
		}
	}
})
export default class LoginInputPage extends Vue {
	@Prop({type: String, required: true})
	phone!: string;

	phoneOutput: E164Number | null = null;
	signinError: string = "";
	submitting: boolean = false;

	created() {
		const maybePhoneNumber = parsePhoneNumberFromString(this.phone);

		if (maybePhoneNumber?.isPossible()) {
			this.phoneOutput = maybePhoneNumber.number;
		}
	}

	async onSubmit() {
		const {phoneOutput: phone} = this;

		if (!phone) {
			this.signinError = "Missing phone number";
			return;
		}

		this.signinError = "";
		this.submitting = true;

		try {
			await this.$apollo.mutate({
				mutation: SendPhoneAccessCodeMutation,
				variables: {
					phoneNumber: phone,
				},
			});
		} catch (e) {

			if (e instanceof ApolloError && e.graphQLErrors) {
				const [gqlError] = e.graphQLErrors;

				if (gqlError) {
					this.signinError = "There was an issue in sending you an SMS. Please check your phone number and try again.";
					return;
				}
			}

			this.signinError = "There was an issue in sending you an SMS. Please try again.";

			return;
		} finally {
			this.submitting = false;
		}

		this.$emit("phone", phone);
		this.$router.push({path: "/login/code", query: {phone: phone as string}});
	}
}
